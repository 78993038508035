import { BookPreset, RenewalBookCoverRenderer } from '@/components/common/Book';
import { GroupBookScheduleBadge } from '@/components/groupTab/GroupBooks/GroupBookScheduleBadge';
import { modularComponent } from '@/utils/modularComponent';

import { GenreHome3InfoPreset } from '../../common/GenreHomeBook';

const GroupBookCoverRenderer = modularComponent(options => {
  const PresetBookCoverRenderer = RenewalBookCoverRenderer.withOptions(options);
  return props => (
    <PresetBookCoverRenderer {...props}>
      <GroupBookScheduleBadge />
    </PresetBookCoverRenderer>
  );
}, RenewalBookCoverRenderer.getOptions());

const GroupBookBasePreset =
  ({ onBeforeNavigate }: { onBeforeNavigate: () => void }): BookPreset =>
  components => ({
    ...components,
    BookLink: modularComponent(() => props => {
      const { BookLink } = components;

      return (
        <span onClickCapture={onBeforeNavigate}>
          <BookLink {...props} />
        </span>
      );
    }),
    BookAuthorItem: modularComponent(() => props => {
      const { BookAuthorItem } = components;

      return (
        <span onClickCapture={onBeforeNavigate}>
          <BookAuthorItem {...props} />
        </span>
      );
    }),
    BookCoverRenderer: GroupBookCoverRenderer,
  });

export const GroupBookPreset = ({ onBeforeNavigate }: { onBeforeNavigate: () => void }) => [
  ...GenreHome3InfoPreset,
  GroupBookBasePreset({ onBeforeNavigate }),
];
