import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';
import { rgba } from '@/utils/rgba';

/**
 * 크롬은 브라우저 언어 설정에 따라 최소 폰트 크기가 달라지는데, 한국어 설정을 할 경우 최소 폰트 크기가 10px이다.
 * @see https://github.com/chromium/chromium/blob/main/chrome/app/resources/platform_locale_settings/locale_settings_win_ko.xtb#L9
 * @see https://github.com/chromium/chromium/blob/main/chrome/app/resources/platform_locale_settings/locale_settings_mac_ko.xtb
 */
const CHROME_MINIMUM_FONT_SIZE = 10;

export const wrapperStyle = (theme: RIDITheme) => css`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: ${rgba(theme.colors.black, 0.5)};
  display: flex;
  align-items: center;
`;

export const wrapperHeightStyle = (height: number): SerializedStyles => css`
  height: ${height}px;
`;

export const wrapperScaleByTextSizeStyle = ({
  leftRightPadding,
  textSize,
}: {
  leftRightPadding: number;
  textSize: number;
}): SerializedStyles => {
  const base = CHROME_MINIMUM_FONT_SIZE / textSize;
  const scale = textSize / CHROME_MINIMUM_FONT_SIZE;

  return css`
    transform-origin: left;
    transform: scaleX(${scale});
    padding-left: ${leftRightPadding * base}px;
    padding-right: ${leftRightPadding * base}px;
    border-top-left-radius: ${4 * base}px;
    border-bottom-right-radius: ${4 * base}px;
  `;
};

export const textStyle = (theme: RIDITheme) => css`
  color: ${theme.colors.white};
  font-weight: 600;
`;

export const textSizeStyle = (size: number): SerializedStyles => css`
  font-size: ${CHROME_MINIMUM_FONT_SIZE}px;
  transform: scaleY(${size / CHROME_MINIMUM_FONT_SIZE});
  transform-origin: center;
`;
