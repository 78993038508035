import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';

import { DESKTOP_BOOK_3INFO_BOOK_HEIGHT, MOBILE_BOOK_3INFO_BOOK_HEIGHT } from '../../common/GenreHomeBook/constants';
import * as sectionStyles from '../index.styles';

export const listWrapperStyle = css`
  height: ${DESKTOP_BOOK_3INFO_BOOK_HEIGHT}px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: ${MOBILE_BOOK_3INFO_BOOK_HEIGHT}px;
    `,
  )};
`;

export const arrowContainerStyle = css`
  ${sectionStyles.horizontalSectionArrowContainerStyle};
  align-items: flex-start;
  & > button {
    margin-top: 110px;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      & > button {
        margin-top: 67px;
      }
    `,
  )};
`;

export const containerStyle = css`
  ${sectionStyles.horizontalSectionScrollContainerStyle};
  display: none;
`;
export const containerVisibleStyle = css`
  display: block;
`;
export const listStyle = css`
  ${sectionStyles.horizontalSectionScrollContainerListStyle};
  display: flex;
`;

export const itemStyle = (margin: number): SerializedStyles => css`
  margin-right: ${margin}px;

  &:last-of-type {
    margin-right: 0;
  }
`;
