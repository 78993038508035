import { BookRenderData } from '@/components/common/Book';
import { GroupUnit } from '@/models/backendsApi/v2/Views/ViewsType';
import {
  getStorageItemJSON,
  LOCAL_STORAGE_KEYS,
  removeStorageItem,
  SESSION_STORAGE_KEYS,
  setStorageItemRaw,
  StorageType,
} from '@/utils/storage';

export interface SavedGroupSectionTabInfo {
  sectionId: number;
  tab: GroupUnit;
  items: BookRenderData[];
  scrollPosition: number;
}

export interface SavedGroupSectionTabInfoMap {
  [sectionid: number]: SavedGroupSectionTabInfo;
}

export const getSavedGroupSectionTabInfoMap = (storageType: StorageType) =>
  (getStorageItemJSON(
    storageType,
    (storageType === 'session' ? SESSION_STORAGE_KEYS : LOCAL_STORAGE_KEYS).SAVED_GROUP_SECTION_TAB_INFO_MAP,
  ) as SavedGroupSectionTabInfoMap) || {};

const setSavedGroupSectionTabInfoMap = (storageType: StorageType, tabInfoMap: SavedGroupSectionTabInfoMap) => {
  try {
    setStorageItemRaw(
      storageType,
      (storageType === 'session' ? SESSION_STORAGE_KEYS : LOCAL_STORAGE_KEYS).SAVED_GROUP_SECTION_TAB_INFO_MAP,
      JSON.stringify(tabInfoMap),
    );
  } catch {
    removeStorageItem(
      storageType,
      (storageType === 'session' ? SESSION_STORAGE_KEYS : LOCAL_STORAGE_KEYS).SAVED_GROUP_SECTION_TAB_INFO_MAP,
    );
  }
};

export const saveGroupSectionTabInfo = (storageType: StorageType, tabInfo: SavedGroupSectionTabInfo) =>
  setSavedGroupSectionTabInfoMap(storageType, {
    ...getSavedGroupSectionTabInfoMap(storageType),
    [tabInfo.sectionId]: tabInfo,
  });

export const deleteGroupSectionTabInfo = (storageType: StorageType, sectionId: number) => {
  const tabInfoMap = getSavedGroupSectionTabInfoMap(storageType);
  delete tabInfoMap[sectionId];

  setSavedGroupSectionTabInfoMap(storageType, tabInfoMap);
};
