import { SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

// TODO: group 더보기 링크를 전부 서버에서 내려주도록 변경하기 전 임시로 사용하는 유틸 함수.
// 현재는 장르홈 실험 때문에 키워드 group 섹션에서만 more_detail_url을 내려주고 있으나, 키워드 group 섹션이 정식 섹션이 된다면
// 요일연재 섹션의 더보기 링크도 more_detail_url로 내려주도록 하고, 이 함수는 삭제한다.
export const getMoreDetailLink = ({
  section,
  groupUnitId,
}: {
  section: SectionByLayout<SectionLayoutType.Group>;
  groupUnitId: number;
}) =>
  section.groups.find(group => group.group_unit.id === groupUnitId)?.more_detail_url?.adult_include ||
  `/group-tab/${section.id}/${groupUnitId}`;
