import { useContext } from 'react';

import { BookDataContext } from '@/components/common/Book';
import { BreakPoint } from '@/components/styles/media';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import * as styles from './GroupBookScheduleBadge.styles';

export const GroupBookScheduleBadge = modularComponent(
  ({ height, leftRightPadding, textSize }) => {
    const wrapperHeightStyle = modularResponsiveStyle(styles.wrapperHeightStyle, height);
    const wrapperScaleByTextSizeStyle = modularResponsiveStyle(
      styles.wrapperScaleByTextSizeStyle,
      leftRightPadding.map(({ value, orBelow }, index) => ({
        value: {
          leftRightPadding: value,
          textSize: textSize[index].value,
        },
        orBelow,
      })),
    );
    const textSizeStyle = modularResponsiveStyle(styles.textSizeStyle, textSize);

    return ({ className }) => {
      const bookData = useContext(BookDataContext);
      const schedule = bookData.cover?.badges?.schedule;

      if (!schedule) {
        return <></>;
      }

      return (
        <div css={[styles.wrapperStyle, wrapperHeightStyle, wrapperScaleByTextSizeStyle]} className={className}>
          <span css={[styles.textStyle, textSizeStyle]}>{schedule}</span>
        </div>
      );
    };
  },
  {
    height: [{ value: 25 }, { value: 20, orBelow: BreakPoint.DesktopSmall }] as ModularResponsiveOption<number>,
    leftRightPadding: [{ value: 6 }, { value: 5, orBelow: BreakPoint.DesktopSmall }] as ModularResponsiveOption<number>,
    textSize: [{ value: 11 }, { value: 9, orBelow: BreakPoint.DesktopSmall }] as ModularResponsiveOption<number>,
  },
);
