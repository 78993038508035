import { forwardRef, useEffect, useMemo, useRef } from 'react';

import { BookRenderData } from '@/components/common/Book';
import {
  HorizontalScrollContainer,
  HorizontalScrollContainerController,
  snapBehavior,
} from '@/components/common/HorizontalScrollContainer';
import { BreakPoint } from '@/components/styles/media';
import { GroupViewItem } from '@/features/genreHome/views/utils/viewDataUtils';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';
import { mergeRefs } from '@/utils/mergeRefs';

import { constants } from '../../common/GenreHomeBook';
import { useSectionTrackingDataContext } from '../../common/SectionTrackingDataContextProvider';
import * as styles from './GroupList.styles';

// @internal
export interface GroupListProps {
  items: GroupViewItem;
  selectedGroupUnitId?: number;
  renderItem: (props: { item: BookRenderData; index: number }) => ReactJSX.Element | null;
}

export const GroupList = forwardRef<HorizontalScrollContainerController, GroupListProps>(
  ({ items, selectedGroupUnitId, renderItem }, ref): ReactJSX.Element => {
    const sectionTrackingData = useSectionTrackingDataContext();

    const isMobile = useResponsiveIsBelow(BreakPoint.DesktopSmall, false);
    const itemSize = isMobile ? constants.MOBILE_BOOK_DEFAULT_COVER_WIDTH : constants.DESKTOP_BOOK_DEFAULT_COVER_WIDTH;
    const defaultItemMargin = isMobile ? 8 : 6;
    const itemStyle = useMemo(() => styles.itemStyle(defaultItemMargin), [defaultItemMargin]);

    const scrollBehavior = useMemo(
      () => snapBehavior(itemSize, { gutterSize: defaultItemMargin }),
      [itemSize, defaultItemMargin],
    );
    const scrollContainerRef = useRef<HorizontalScrollContainerController>(null);
    useEffect(() => scrollContainerRef.current?.getScroller()?.scrollTo({ left: 0 }), [selectedGroupUnitId]);

    const mergedRef = useMemo(() => mergeRefs([ref, scrollContainerRef]), [ref, scrollContainerRef]);

    return (
      <div css={styles.listWrapperStyle}>
        {Object.entries(items).map(([groupUnitId, unitItems]) => {
          const visible = Number(groupUnitId) === selectedGroupUnitId;

          return (
            <HorizontalScrollContainer
              css={[styles.containerStyle, visible && styles.containerVisibleStyle]}
              arrowContainerCss={styles.arrowContainerStyle}
              scrollBehavior={scrollBehavior}
              eventParams={sectionTrackingData}
              ref={visible ? mergedRef : undefined}
              key={groupUnitId}>
              <ul css={styles.listStyle}>
                {unitItems.map((item, index) => (
                  <li key={item.bookId} css={itemStyle}>
                    {renderItem({ item, index })}
                  </li>
                ))}
              </ul>
            </HorizontalScrollContainer>
          );
        })}
      </div>
    );
  },
);

export type GroupListScrollContainerController = HorizontalScrollContainerController;
