import { css } from '@emotion/react';
import colors from '@ridi/colors';

import { scrollBarHidden } from '@/components/styles';
import { BreakPoint, orBelow, touchDevice } from '@/components/styles/media';

const SCROLL_DISABLED_TAB_MIN_WIDTH = 320;

export const containerStyle = css`
  margin-bottom: 8px;
`;

export const containerScrollDisabledStyle = orBelow(
  SCROLL_DISABLED_TAB_MIN_WIDTH,
  css`
    ${scrollBarHidden};
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  `,
);

export const groupTabsStyle = touchDevice(css`
  padding-left: 30px;
  padding-right: 30px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-left: 16px;
      padding-right: 16px;
    `,
  )}
`);

export const groupTabsScrollDisabledStyle = css`
  display: flex;
  justify-content: space-between;
  ${touchDevice(css`
    min-width: ${SCROLL_DISABLED_TAB_MIN_WIDTH}px;
  `)}
`;

export const tabWrapperStyle = css`
  position: relative;
`;

export const tabWrapperScrollEnabledStyle = css`
  display: inline-block;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0px;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-right: 0px;
    `,
  )}
`;

export const tabStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 38px;
  min-height: 38px;
  font-size: 16px;
  letter-spacing: -0.01em;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      min-width: 32px;
      min-height: 32px;
      font-size: 15px;
    `,
  )}
`;

export const tabScrollEnabledStyle = css`
  padding: 0px 12px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0px 10px;
    `,
  )}
`;

export const tabScrollDisabledStyle = css`
  padding: 0px 9px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0px 6px;
    `,
  )}
`;

export const tabTouchAreaStyle = css`
  display: none;

  ${touchDevice(css`
    display: block;
    position: absolute;
    top: 0px;
    left: 50%;
    width: 200%;
    height: 100%;
    transform: translateX(-50%);
  `)}

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 100%;
    `,
  )}
`;

export const tabArrowStyle = css`
  box-shadow: none;
  border-radius: 0;
  border: 0;

  color: ${colors.slateGray30};
  font-size: 14px;

  display: flex;
  align-items: center;

  width: 40px;
  height: 44px;
  padding-top: 2px;

  &:first-of-type {
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0)
      linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 27.6%, rgb(255, 255, 255) 53.65%)
      repeat scroll 0% 0%;
  }

  &:last-of-type {
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0)
      linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 27.6%, rgb(255, 255, 255) 53.65%)
      repeat scroll 0% 0%;
  }
`;

export const tabArrowContainerStyle = css`
  padding: 0;
`;
