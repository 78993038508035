import styled from '@emotion/styled';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import {
  HorizontalScrollContainer,
  HorizontalScrollContainerController,
  HorizontalScrollContainerProps,
} from '@/components/common/HorizontalScrollContainer';
import { GroupUnit } from '@/models/backendsApi/v2/Views/ViewsType';

import { SectionTrackingDataContextType } from '../../common/SectionTrackingDataContextProvider';
import * as styles from './GroupTabs.styles';

export interface GroupTabsProps {
  type: 'DailyWebtoon' | 'Keyword';
  selectedItem?: GroupUnit;
  items: GroupUnit[];
  onChange: (item: GroupUnit) => void;
  sectionTrackingData: SectionTrackingDataContextType;
}

const StyledGroupTabs = styled.div<{ scrollEnabled?: boolean }>`
  ${styles.groupTabsStyle};

  ${({ scrollEnabled }) => !scrollEnabled && styles.groupTabsScrollDisabledStyle};
`;

const StyledTabWrapper = styled.div<{ scrollEnabled?: boolean }>`
  ${styles.tabWrapperStyle};

  ${({ scrollEnabled }) => scrollEnabled && styles.tabWrapperScrollEnabledStyle};
`;

const StyledTab = styled.button<{ selected?: boolean; scrollEnabled?: boolean }>`
  ${styles.tabStyle};
  font-weight: ${({ selected }) => (selected ? 700 : 500)};
  color: ${({ selected, theme }) => (selected ? theme.colors.bgBase : theme.colors.grey500)};
  background-color: ${({ selected, theme }) => (selected ? theme.colors.fillCta : 'transparent')};
  border-radius: ${({ selected }) => (selected ? '999px' : 0)};
  ${({ scrollEnabled }) => (scrollEnabled ? styles.tabScrollEnabledStyle : styles.tabScrollDisabledStyle)};
`;

const StyledTabTouchArea = styled.label`
  ${styles.tabTouchAreaStyle};

  ${StyledTabWrapper}:first-of-type & {
    left: 0px;
    transform: none;
  }

  ${StyledTabWrapper}:last-of-type & {
    left: auto;
    right: 0px;
    transform: none;
  }
`;

/** HSC와 focus() 동작이 동일하지만 스타일을 제외하기 위해 별도로 구현한 Container */
const ScrollDisabledContainer = forwardRef<HorizontalScrollContainerController, HorizontalScrollContainerProps>(
  ({ children, className }, ref) => {
    const nodeRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      focus: element => {
        const node = nodeRef.current;
        if (!node) {
          return;
        }

        const { offsetLeft = 0, clientWidth = 0 } = element;
        node.scrollTo({
          top: 0,
          left: offsetLeft - (node.clientWidth - clientWidth) / 2,
          behavior: 'smooth',
        });
      },
      getScroller: () => null,
    }));

    return (
      <div ref={nodeRef} className={className}>
        {children}
      </div>
    );
  },
);

const ScrollEnabledContainer = HorizontalScrollContainer;

export const GroupTabs = ({
  type,
  selectedItem,
  items,
  onChange,
  sectionTrackingData,
}: GroupTabsProps): ReactJSX.Element => {
  const scrollContainerElementRef = useRef<HorizontalScrollContainerController>(null);
  const tabElementsRefs = useRef<Record<number, HTMLElement>>({});

  const setTabElementRef = (id: number) => (element: HTMLElement | null) => {
    if (!element) {
      delete tabElementsRefs.current[id];
      return;
    }

    tabElementsRefs.current[id] = element;
  };

  const handleTabClick = (item: GroupUnit) => {
    if (selectedItem?.id === item.id) {
      return;
    }

    onChange(item);
  };

  useEffect(() => {
    if (!scrollContainerElementRef.current) {
      return;
    }

    const activeTabElement = tabElementsRefs.current[selectedItem?.id ?? 0];

    if (!activeTabElement) {
      return;
    }

    scrollContainerElementRef.current.focus(activeTabElement);
  }, [selectedItem]);

  const isKeywordSection = type === 'Keyword';
  const Container = isKeywordSection ? ScrollEnabledContainer : ScrollDisabledContainer;

  return (
    <Container
      ref={scrollContainerElementRef}
      css={[styles.containerStyle, !isKeywordSection && styles.containerScrollDisabledStyle]}
      arrowCss={styles.tabArrowStyle}
      arrowContainerCss={styles.tabArrowContainerStyle}>
      <StyledGroupTabs scrollEnabled={isKeywordSection}>
        {items.map((item, index) => {
          const tabId = `group_section_tab_${item.id}`;
          const isSelected = item.id === selectedItem?.id;
          const eventParams = {
            ...sectionTrackingData.params,
            tab_id: item.id,
            tab_title: item.name,
            tab_index: index,
          };

          return (
            <TrackClickEvent
              screenName={sectionTrackingData.screenName}
              target="group_section_tab"
              params={eventParams}
              key={item.id}>
              <StyledTabWrapper ref={setTabElementRef(item.id)} scrollEnabled={isKeywordSection}>
                <StyledTab
                  selected={isSelected}
                  scrollEnabled={isKeywordSection}
                  type="button"
                  onClick={() => handleTabClick(item)}
                  id={tabId}>
                  {item.name}
                </StyledTab>
                {!isKeywordSection && <StyledTabTouchArea htmlFor={tabId} />}
              </StyledTabWrapper>
            </TrackClickEvent>
          );
        })}
      </StyledGroupTabs>
    </Container>
  );
};
